import { Injectable } from '@angular/core';
import { isSessionStorageAvailable } from '../../../../share/utils/functions';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenService {
  private readonly TOKEN_KEY = 'refreshToken';

  get hasToken() {
    return this.retrieveToken() != null;
  }

  storeToken(token: string): void {
    if (isSessionStorageAvailable()) {
      sessionStorage.setItem(this.TOKEN_KEY, token);
    }
  }

  retrieveToken(): string | null {
    if (isSessionStorageAvailable()) {
      return sessionStorage.getItem(this.TOKEN_KEY);
    }
    return null;
  }

  removeToken(): void {
    if (isSessionStorageAvailable()) {
      sessionStorage.removeItem(this.TOKEN_KEY);
    }
  }
}
